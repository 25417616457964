<template>
  <div class="empty">
    <img
      src="@/assets/img/common/icon36.png"
      class="focus_img"
    />
    <!-- src="https://www.bzwz.com/static/m/images/icon36.png" -->
    <div>
      暂无<span>{{ text }}</span
      >信息 <span v-if="showLive">
        ，如有疑问，请<a
        href="https://tb.53kf.com/code/client/a7c96e4ceb10e87d6fc792aec24104e05/3"
        >联系客服</a
      >
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "Empty",
  props: {
    text: {
      type: String,
      default: "",
    },
    showLive: {
      type: Boolean,
      default: true,
    },
  },
});
</script>

<style lang="scss" scoped>
.empty {
  padding-top: 160px;
  text-align: center;
  padding-bottom: 50px;

  color: #9f9f9f;
  font-size: 13px;
}
.empty img {
  width: 132px;
  height: 104px;
  display: block;
  margin: 0 auto;
  margin-bottom: 15px;
}
.empty a {
  font-size: 13px;
  color: #df0024;
}
</style>